<template>
  <div>
    <draggable v-model="optionsList" :disabled="!allows_crud" handle=".handle">
      <transition-group>
        <template>
          <div
            v-for="option in optionsList"
            :key="`draggable-options-${Question.question_type}-${Question.test}-${Question.id}-${option.id}`"
            class="options-div"
            :class="{ 'draggable-section': allows_crud }"
          >
            <div
              class="option-div d-flex align-middle"
              :class="{ 'option-div-allow-crud': allows_crud }"
            >
              <div class="d-flex">
                <b-icon
                  v-if="allows_crud"
                  class="handler-icon handle"
                  icon="arrows-expand"
                  :class="{ 'grabbable-cursor': allows_crud }"
                ></b-icon>
                <template
                  v-if="
                    !preview_evaluation &&
                    !evaluatee_view &&
                    !evaluator_view &&
                    ((institution &&
                      institution.internal_use_id != 'duoc_uc') ||
                      (Question.subtype == 2 &&
                        institution &&
                        institution.internal_use_id == 'duoc_uc'))
                  "
                >
                  <b-form-checkbox
                    tabindex="-1"
                    class="square-icon mr-2 ml-2 noprint"
                    :checked="
                      Question.subtype == 1
                        ? option.score == Question.max_score
                        : Question.subtype == 2
                        ? option.score > 0
                        : false
                    "
                    :disabled="true"
                  ></b-form-checkbox>
                  <b-form-checkbox
                    class="square-icon-print mr-2 ml-2"
                    tabindex="-1"
                  ></b-form-checkbox>
                </template>
                <template
                  v-if="
                    !preview_evaluation &&
                    !evaluatee_view &&
                    !evaluator_view &&
                    Question.subtype == 1 &&
                    institution &&
                    institution.internal_use_id == 'duoc_uc'
                  "
                >
                  <div
                    v-b-tooltip.top.noninteractive.v-danger="
                      Question.max_score == 0
                        ? 'Para poder asignar la respuesta correcta, tiene que darle puntaje a la pregunta.'
                        : ''
                    "
                    class="mt-1"
                    :class="{ 'cursor-help': Question.max_score == 0 }"
                  >
                    <b-form-checkbox
                      tabindex="-1"
                      class="square-icon mr-2 ml-2 noprint"
                      :checked="option.score > 0"
                      :disabled="option.score > 0 || Question.max_score == 0"
                      @change="changeScoreSimpleOption(option.id)"
                    ></b-form-checkbox>
                    <b-form-checkbox
                      class="square-icon-print mr-2 ml-2"
                      tabindex="-1"
                    ></b-form-checkbox>
                  </div>
                </template>
                <template
                  v-else-if="
                    preview_evaluation && !evaluatee_view && !evaluator_view
                  "
                >
                  <template v-if="Question.how_many_selected > 1">
                    <b-form-checkbox
                      class="square-icon mr-2 ml-2"
                      tabindex="-1"
                      v-model="option.tem_check"
                    ></b-form-checkbox>
                  </template>
                  <template v-else-if="Question.how_many_selected == 1">
                    <b-form-checkbox
                      class="square-icon mr-2 ml-2"
                      tabindex="-1"
                      :checked="option.id == selected_id"
                      @change="selectOption(option.id)"
                    ></b-form-checkbox>
                  </template>
                </template>
                <template
                  v-else-if="
                    evaluatee_view && !finished_test && !evaluator_view
                  "
                >
                  <template v-if="Question.how_many_selected > 1">
                    <b-form-checkbox
                      class="square-icon mr-2 ml-2"
                      tabindex="-1"
                      :checked="
                        MultipleChoiceQuestionOptionsSelected.includes(
                          option.id
                        )
                      "
                      :disabled="temp_disable_options"
                      @change="changeEvaluateeCheck(option)"
                    ></b-form-checkbox>
                  </template>
                  <template v-else-if="Question.how_many_selected == 1">
                    <b-form-checkbox
                      class="square-icon mr-2 ml-2"
                      tabindex="-1"
                      :checked="option.id == selected_id"
                      :disabled="temp_disable_options"
                      @change="changeEvaluateeCheck(option)"
                    ></b-form-checkbox>
                  </template>
                </template>
                <template
                  v-else-if="
                    (evaluatee_view && finished_test) || evaluator_view
                  "
                >
                  <template v-if="Question.how_many_selected > 1">
                    <b-form-checkbox
                      class="square-icon mr-2 ml-2"
                      tabindex="-1"
                      :checked="
                        MultipleChoiceQuestionOptionsSelected.includes(
                          option.id
                        )
                      "
                      :disabled="true"
                    ></b-form-checkbox>
                  </template>
                  <template v-else-if="Question.how_many_selected == 1">
                    <b-form-checkbox
                      class="square-icon mr-2 ml-2"
                      tabindex="-1"
                      :checked="option.id == selected_id"
                      :disabled="true"
                    ></b-form-checkbox>
                  </template>
                </template>
              </div>
              <div class="alternative-option">
                {{ String.fromCharCode(96 + option.order) + ")" }}
              </div>
              <div class="option-text ml-1">
                <template v-if="allows_crud">
                  <b-form-input
                    id="input-name"
                    v-b-tooltip.top.noninteractive.v-danger="
                      `${
                        option.title.trim() == ''
                          ? 'Escribe al menos un carácter'
                          : ''
                      }`
                    "
                    :class="{ 'option-border': option.title.trim() == '' }"
                    v-model="option.title"
                    @change="
                      debouncePatchOption(option.id, { title: option.title })
                    "
                    size="sm"
                  ></b-form-input>
                </template>
                <template v-else>
                  <div
                    class="border-div pl-1"
                    :class="{
                      'unselectable-text':
                        (user && user.groups.includes(7)) || preview_evaluation,
                      'input-option-title': preview_evaluation,
                    }"
                  >
                    {{ option.title }}
                  </div>
                </template>
              </div>
              <div
                v-if="
                  !preview_evaluation &&
                  (Question.subtype == 2 ||
                    (institution && institution.internal_use_id != 'duoc_uc'))
                "
              >
                <b-form-input
                  v-if="allows_crud"
                  class="mr-2 ml-2 mt-0 input-number"
                  :class="{
                    'score-input-warning': optionsMaxScore > Question.max_score,
                  }"
                  v-b-tooltip.top.noninteractive.v-danger="
                    `${
                      optionsMaxScore > Question.max_score
                        ? 'El puntaje total de las opciones es mayor que el puntaje total de la pregunta'
                        : ''
                    }`
                  "
                  v-model="option.score"
                  @input="PatchOptionScore(option)"
                  type="number"
                  min="0"
                  size="sm"
                ></b-form-input>
                <template v-else>
                  <div class="border-div ml-2 mr-3 pl-1 pr-1 noprint">
                    {{ option.score }}
                  </div>
                </template>
              </div>
              <b-button
                variant="danger"
                size="sm"
                class="delete-btn mr-1 ml-2 p-0"
                v-if="allows_crud"
                tabindex="-1"
                v-can="'evaluations2.delete_multiplechoicequestionoption'"
                v-b-tooltip.top.noninteractive.v-secondary="`Eliminar`"
                @click="askForDeteleOption(option.id)"
              >
                <b-icon-trash></b-icon-trash>
              </b-button>
            </div>
          </div>
        </template>
      </transition-group>
    </draggable>
    <b-button
      v-if="allows_crud"
      variant="primary"
      size="sm"
      class="mt-1"
      tabindex="-1"
      @click="createdOption()"
    >
      + Agregar alternativa
    </b-button>
    <div v-if="!preview_evaluation" class="limit-container mt-2 noprint">
      <div class="option_selected-info mr-1" v-if="Question.subtype == 2">
        <span> <strong>Opciones a seleccionar: </strong></span>
        <b-form-input
          v-if="allows_crud"
          :key="key_how_many_selected"
          class="ml-2 mt-0 input-number"
          type="number"
          :min="Question.subtype == 2 ? 2 : 1"
          size="sm"
          v-model="Question.how_many_selected"
          @change="patchOptionsSelected"
        >
        </b-form-input>
        <div v-else class="ml-2 mt-0">{{ Question.how_many_selected }}</div>
      </div>
      <div
        class="selector-container"
        v-if="
          Question &&
          Question.how_many_selected &&
          Question.how_many_selected > 1
        "
      >
        <div class="method-score">
          <div class="text-method-score">
            <strong>Método de cálculo de puntaje:</strong>
          </div>
          <b-form-select
            v-if="allows_crud"
            v-model="Question.automated_score_type"
            :options="maxAutomatedScoreTypes"
            text-field="value"
            value-field="id"
            size="sm"
            class="ml-2"
            style="width: 120px"
            @change="patchQuestionAutomatedScoreType"
          >
          </b-form-select>
          <div v-else class="ml-2">{{ showAutomatedScoreType }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import { generateUniqueId, toast } from "@/utils/utils";

export default {
  name: "SelectQuestionOptions",
  components: {
    draggable,
  },
  props: {
    Question: {
      type: Object,
      required: true,
    },
    QuestionScore: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          evaluatee: null,
          multiple_choice_question: this.Question.id,
          attachments: [],
        };
      },
    },
    allows_crud: {
      type: Boolean,
      required: true,
    },
    preview_evaluation: {
      type: Boolean,
      required: true,
    },
    evaluatee_view: {
      type: Boolean,
      default: false,
    },
    evaluator_view: {
      type: Boolean,
      default: false,
    },
    is_running_test: {
      type: Boolean,
      default: false,
    },
    finished_test: {
      type: Boolean,
      default: false,
    },
    // para mostrar el resultado del evaluado al profesor.
    user_id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      selected_id: null,
      question_score: { ...this.QuestionScore },
      key_how_many_selected: 0,
      temp_disable_options: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      multiple_choice_question_options:
        names.NEW_MULTIPLE_CHOICE_QUESTION_OPTIONS,
      maxAutomatedScoreTypes: names.MULTIPLE_QUESTION_SCORE_TYPES,
      evaluatee_multiple_choice_question_options:
        names.EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTIONS,
    }),
    optionsList: {
      get() {
        return this.multiple_choice_question_options
          .filter((x) => x.multiple_choice_question == this.Question.id)
          .sort((a, b) => a.order - b.order);
      },
      set(list) {
        list.forEach((element, index) => {
          if (!isNaN(element.id)) {
            this.patchOption(element.id, { order: index + 1 });
          }
        });
      },
    },
    optionsMaxScore() {
      let score = 0;
      this.optionsList.forEach((element) => {
        score += parseFloat(element.score);
      });
      return score;
    },
    showAutomatedScoreType() {
      const type = this.maxAutomatedScoreTypes.find(
        (x) => x.id == this.Question.automated_score_type
      );
      if (type) return type.value;
      else return "";
    },
    evaluateeMultipleChoiceQuestionOptions() {
      if (isNaN(this.question_score.id)) return [];
      return this.evaluatee_multiple_choice_question_options.filter(
        (x) => x.evaluatee_multiple_choice_question == this.question_score.id
      );
    },
    MultipleChoiceQuestionOptionsSelected() {
      return this.evaluateeMultipleChoiceQuestionOptions.map(
        (x) => x.multiple_choice_question_option
      );
    },
  },
  methods: {
    // no olvidar rellenar el selected_id en el created para que tenga el id del option.
    changeEvaluateeCheck(option) {
      if (this.Question.how_many_selected == 1) {
        this.singleHowManySelectedScore(option);
      } else if (this.Question.how_many_selected > 1) {
        this.multyHowManySelectedScore(option);
      }
    },
    singleHowManySelectedScore(option) {
      this.temp_disable_options = true;
      if (isNaN(this.question_score.id)) this.createQuestionScore(option);
      else if (this.selected_id == null) {
        if (!isNaN(this.question_score.id)) {
          this.$store
            .dispatch(names.POST_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION, {
              evaluatee_multiple_choice_question: this.question_score.id,
              multiple_choice_question_option: option.id,
            })
            .then(() => {
              this.selected_id = option.id;
              // this.temp_disable_options = true;
              toast("Se ha enviado su respuesta.");
            });
        }
      } else if (
        this.selected_id == option.id &&
        !isNaN(this.question_score.id)
      ) {
        const option_score = this.evaluateeMultipleChoiceQuestionOptions.find(
          (x) =>
            x.evaluatee_multiple_choice_question == this.question_score.id &&
            x.multiple_choice_question_option == option.id
        );
        if (option_score)
          this.$store
            .dispatch(
              names.DELETE_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION,
              option_score.id
            )
            .then(() => {
              this.selected_id = null;
              // this.temp_disable_options = true;
            });
      } else if (
        this.selected_id != option.id &&
        !isNaN(this.question_score.id)
      ) {
        const option_score = this.evaluateeMultipleChoiceQuestionOptions.find(
          (x) =>
            x.evaluatee_multiple_choice_question == this.question_score.id &&
            x.multiple_choice_question_option == this.selected_id
        );
        if (option_score)
          this.$store
            .dispatch(
              names.DELETE_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION,
              option_score.id
            )
            .then(() => {
              this.$store
                .dispatch(
                  names.POST_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION,
                  {
                    evaluatee_multiple_choice_question: this.question_score.id,
                    multiple_choice_question_option: option.id,
                  }
                )
                .then(() => {
                  this.selected_id = option.id;
                  // this.temp_disable_options = true;
                  toast("Se ha enviado su respuesta.");
                });
            });
      }
    },
    multyHowManySelectedScore(option) {
      this.temp_disable_options = true;
      // Eliminar la alternativa seleccionada anteriormente.
      if (isNaN(this.question_score.id)) this.createQuestionScore(option);
      else if (!isNaN(this.question_score.id)) {
        const evaluatee_option_score =
          this.evaluateeMultipleChoiceQuestionOptions.find(
            (x) =>
              x.evaluatee_multiple_choice_question == this.question_score.id &&
              x.multiple_choice_question_option == option.id
          );
        if (evaluatee_option_score) {
          this.$store.dispatch(
            names.DELETE_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION,
            evaluatee_option_score.id
          );
        } else {
          this.$store
            .dispatch(names.POST_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION, {
              evaluatee_multiple_choice_question: this.question_score.id,
              multiple_choice_question_option: option.id,
            })
            .then(() => {
              toast("Se ha enviado su respuesta.");
            });
        }
      }
    },
    createQuestionScore(option) {
      this.$store
        .dispatch(names.POST_EVALUATEE_MULTIPLE_CHOICE_QUESTION, {
          evaluatee: this.user_id,
          multiple_choice_question: this.Question.id,
          attachments: [],
        })
        .then((response) => {
          this.question_score = response;
          this.$store
            .dispatch(names.POST_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION, {
              evaluatee_multiple_choice_question: response.id,
              multiple_choice_question_option: option.id,
            })
            .then(() => {
              if (this.Question.how_many_selected == 1)
                this.selected_id = option.id;
              toast("Se ha enviado su respuesta.");
            });
        });
    },
    selectOption(option_id) {
      this.selected_id = option_id;
    },
    patchOptionsSelected(value) {
      if (value >= 1 && value != null && value != "") {
        if (this.Question.subtype == 2 && value < 2) {
          this.Question.how_many_selected = 2;
          this.key_how_many_selected += 1;
        } else
          this.patchQuestion({
            how_many_selected: value,
            subtype: value == 1 ? 1 : 2,
          });
      }
    },
    patchQuestionAutomatedScoreType(value) {
      this.patchQuestion({ automated_score_type: value });
    },
    patchQuestion(item) {
      if (this.Question.question_type == 2) {
        const payload = {
          new_multiple_choice_question_id: this.Question.id,
          item: item,
        };
        this.$store.dispatch(names.PATCH_NEW_MULTIPLE_CHOICE_QUESTION, payload);
      }
      toast("Pregunta actualizada.");
    },
    createdOption() {
      if (this.optionsList.length > 0)
        this.$store.dispatch(names.POST_NEW_MULTIPLE_CHOICE_QUESTION_OPTION, {
          title: "",
          description: "",
          score: 0,
          order: this.optionsList[this.optionsList.length - 1].order + 1,
          multiple_choice_question: this.Question.id,
        });
      else
        this.$store.dispatch(names.POST_NEW_MULTIPLE_CHOICE_QUESTION_OPTION, {
          title: "",
          description: "",
          score: 0,
          order: 1,
          multiple_choice_question: this.Question.id,
        });
      toast("Se agregó una alternativa");
    },
    patchOption(option_id, item) {
      const payload = {
        new_multiple_choice_question_option_id: option_id,
        item: item,
      };
      this.$store
        .dispatch(names.PATCH_NEW_MULTIPLE_CHOICE_QUESTION_OPTION, payload)
        .then(() => {
          if (
            this.optionsList.filter((x) => x.score > 0).length > 1 &&
            this.Question.subtype == 2
          )
            this.patchQuestion({
              how_many_selected: this.optionsList.filter((x) => x.score > 0)
                .length,
              // subtype:
              //   this.optionsList.filter((x) => x.score > 0).length == 1 ? 1 : 2,
            });
        });
      toast("Se modificó la alternativa");
    },
    PatchOptionScore(option) {
      if (option.score >= 0 && option.score != null && option.score != "") {
        this.debouncePatchOption(option.id, {
          score: option.score,
        });
      }
    },
    debouncePatchOption(option_id, item) {
      if (!isNaN(option_id)) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.patchOption(option_id, item);
        }, 250);
      }
    },
    askForDeteleOption(option_id) {
      this.$swal({
        title: `<p>¿Está seguro de que desea eliminar la alternativa?</p>`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(
              names.DELETE_NEW_MULTIPLE_CHOICE_QUESTION_OPTION,
              option_id
            )
            .then(() => {
              toast("Se eliminó la alternativa ");
            });
        }
      });
    },
    async changeScoreSimpleOption(option_id) {
      let list = this.optionsList.filter((x) => x.score > 0);
      for await (let option of list) {
        if (option.score > 0) this.patchOption(option.id, { score: 0 });
      }
      await this.patchOption(option_id, { score: this.Question.max_score });
    },
  },
  watch: {
    temp_disable_options() {
      if (this.temp_disable_options) {
        this.timeout_temp = setTimeout(() => {
          this.temp_disable_options = false;
          if (this.timeout_temp) clearTimeout(this.timeout);
        }, 500);
      }
    },
  },
  mounted() {
    if (!isNaN(this.question_score.id)) {
      const evaluate_option_score =
        this.evaluateeMultipleChoiceQuestionOptions.find(
          (x) => x.evaluatee_multiple_choice_question == this.question_score.id
        );
      if (evaluate_option_score)
        this.selected_id =
          evaluate_option_score.multiple_choice_question_option;
    }
  },
  created() {},
};
</script>

<style scoped>
.cursor-help {
  cursor: help;
}
.option-border {
  border: 1px solid #dc3545 !important;
  color: red;
}
.input-option-title {
  border: none !important;
}
.delete-btn {
  border: 1px;
  max-height: 30px;
  width: 27px;
}
.delete-btn:hover {
  background-color: #e45d6b;
}
.unselectable-text {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.score-input-warning {
  border: 1px solid #dc3545 !important;
  color: red;
}
.input-number {
  --bottom-border-color: var(--kl-menu-color);
  max-width: 52px;
  border: none;
  border: 1px solid #c9c9c9;
  overflow: hidden;
  border-radius: 3px;
  text-align: center;
}
input[type="text"] {
  margin: 0 !important;
}
.form-control {
  padding: 0 !important;
}
.input-number:focus {
  outline: none !important;
  border-bottom: 2px solid var(--bottom-border-color);
}
.border-div {
  min-width: 50px;
  border: 1px solid #c9c9c9;
  min-height: 25px;
  border-radius: 3px;
}
.btn-delete-option {
  background-color: #dc3545;
  color: var(--secondary-font-color);
  border: none;
  margin-top: auto;
  margin-bottom: auto;
  /* background: transparent; */
}
.btn-delete-option:hover {
  background-color: #e45d6b;
}
.handler-icon {
  background: var(--primary-color);
  width: 25px;
  height: 90%;
  border-radius: 5px;
  padding: 2px;
  margin-right: 0.5rem;
}
.handler-icon:hover {
  box-shadow: 0px 4px 12px -2px var(--secondary-color);
  background-color: var(--kl-menu-color);
  color: white;
}
.grabbable-cursor {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.grabbable-cursor:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.option-text {
  width: 100%;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
}
.alternative-option {
  margin-top: auto;
  margin-bottom: auto;
}
.square-icon {
  margin-top: auto;
  margin-bottom: auto;
}
.square-icon-print {
  display: none;
}
.option-div {
  margin: 1rem;
}
.option-div-allow-crud {
  margin: 0;
}
.draggable-section {
  padding: 2px;
  padding-top: 8px;
  border-radius: 5px;
}
.draggable-section:hover {
  background: rgb(249, 249, 249);
}
.options-div:first-of-type {
  margin-top: 0.7em;
}
.option-textarea {
  width: 98%;
}
.option_selected-info {
  display: flex;
  align-items: center;
}
.selector-container {
  display: flex;
}
.limit-container {
  display: flex;
}
.method-score {
  width: 380px;
  display: flex;
  align-items: center;
}
.text-method-score {
  margin-left: 1rem;
}
@media (max-width: 750px) {
  .limit-container {
    flex-direction: column;
  }
  .method-score {
    text-align: left !important;
    width: 280px;
  }
  .text-method-score {
    margin: 0;
  }
  .delete-btn {
    margin-left: 15px !important;
  }
  .options-div,
  .handler-icon,
  .square-icon {
    margin-left: 0 !important;
  }
  .border-div {
    margin-right: 10px !important;
  }
}
@media print {
  .square-icon-print {
    display: block;
  }
  .border-div {
    margin-right: 1rem;
    border: none;
  }
}
</style>

